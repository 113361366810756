import {
    tokensExpireSelector,
    tokensAccessSelector,
    tokensLogedTimeSelector,
} from "../../store/slices/tokensSlice";
import {checkExpire} from "../../helpers";
import {InternalAxiosRequestConfig} from "axios";
import {IStore} from "../../models";




export const makeRefresh = (config: InternalAxiosRequestConfig, store: IStore) => {
    if (!(config.url as string).includes('refresh')) {
        let expire = tokensExpireSelector(store.getState());
        let lastUpdate = tokensLogedTimeSelector(store.getState());
        if (expire && checkExpire(+expire,lastUpdate)) {
            // store.dispatch(updateTokensAction());
        }
    }
}


export const writeAccess = (config: InternalAxiosRequestConfig,store: IStore) => {
    let token = tokensAccessSelector(store.getState());
    if (token) {
        config.headers['ROOT-ACCESS'] = `${token}`;
    }

    return config;
}
