import {IError, IFile} from "../../../models";
import {call, put, all, select, race, take} from "@redux-saga/core/effects";
import {errorsUiUpdateAction, errorsUploadFileAction} from "../../slices/errorSlice";
import {
    ADD_UPLOAD_FILES, filesChangeUpload, uploadFilesSelector,
} from "../../slices/filesSlice";
import {fetchFileSaga} from "./fetchFileSaga";

export function* handleUploadFilesSaga(): Generator {

    try {
        yield put(errorsUiUpdateAction(null));
        yield put(errorsUploadFileAction(null));
        yield put(filesChangeUpload(true))

        const files  = yield select(uploadFilesSelector);

        yield all((files as IFile[]).map((el,i) =>
           race({
               task:  call(() => fetchFileSaga({
                   type: ADD_UPLOAD_FILES,
                   payload: {item: el,id: i}}),
               ),
               cancel: take('STOP' + el.id),

           })
        ));
    }
    catch (e) {
        if ((e as IError).status) {
            yield put(errorsUploadFileAction(e as IError))
        }
        else {
            yield put(errorsUiUpdateAction(e as IError));

        }
    }
    finally {
        yield put(filesChangeUpload(false))

    }
}
