import {Router} from "@remix-run/router";
import {getContext} from "redux-saga/effects";
import {HOME} from "../../../routes/paths";
import {fetchConfirmEmail} from "../../../services/AuthService";
import {call, put} from "@redux-saga/core/effects";
import { updateEmailConfirmAction, updateTokensAction} from "../../slices/tokensSlice";
import {AxiosResponse} from "axios";
import {IAction, IError} from "../../../models";
import {uiChangeLoadingAction, uiUpdateTimerAction} from "../../slices/uiSlice";
import {errorsConfirmEmailUpdateAction, errorsUiUpdateAction} from "../../slices/errorSlice";


export function* confirmEmailSaga (action: IAction<{key: string}>): Generator<{type: String, action?: any}> | Generator{

    try {
        yield put(errorsConfirmEmailUpdateAction(null))
        yield put(errorsUiUpdateAction(null))
        yield put(uiChangeLoadingAction(true))
        let res: AxiosResponse | IError | unknown  = yield call(() => fetchConfirmEmail(action.payload.key));

        if((res as AxiosResponse).status === 204) {

            yield put(updateEmailConfirmAction(true));

            yield put(updateTokensAction());

            const router: Router | unknown = yield (getContext('router'));
            yield (router as Router).navigate(HOME);

            yield put(uiUpdateTimerAction(0));

        }
        else {
            throw res;
        }
    }
    catch (e) {
        if ((e as IError).status) {
            yield put(errorsConfirmEmailUpdateAction((e as IError)));
        }
        else {
            yield put(errorsUiUpdateAction( (e as IError)));
        }

    }
    finally {
        yield put(uiChangeLoadingAction(false))
    }
}
