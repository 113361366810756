import {put,call} from "@redux-saga/core/effects";
import {clearTokensAction} from "../slices/tokensSlice";
import { logOutUserAction} from "../slices/userSlice";
import {Router} from "@remix-run/router";
import {getContext} from "redux-saga/effects";
import {SIGN_IN} from "../../routes/paths";
import {uiChangeIsShowMessageAction, uiUpdateTimerAction} from "../slices/uiSlice";
import {signInClearStateAction} from "../slices/signInSlice";
import {signUpClearStateAction} from "../slices/signUpSlice";
import {fetchSignOut} from "../../services/AuthService";
import {AxiosResponse} from "axios";
import {clearSettings} from "../slices/settingsSlice";

export function* logoOutSaga(): Generator {
    try {
        let resp = yield call(() => fetchSignOut());

        if ((resp as AxiosResponse).status === 204) {
            yield put(logOutUserAction());
            yield put(clearSettings());
            yield put(clearTokensAction());
            yield put(signInClearStateAction())
            yield put(signUpClearStateAction())
            yield localStorage.clear();
            yield put(uiUpdateTimerAction(0));
            yield put(uiChangeIsShowMessageAction(false));
            const router: Router | unknown = yield (getContext('router'));
            yield (router as Router).navigate(SIGN_IN,{replace: true})
        }

    }
    catch (e) {
        console.log(e)
    }


}
