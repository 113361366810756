const readFile = (file: File) => {
    return new Promise((res,rej) => {
        let fR = new FileReader();
        fR.onload = () => {
            res({
                name: file.name,
                src: fR.result,
                file: file,
            })
        };
        fR.onerror = () => {
            rej(fR)
        };

        fR.readAsDataURL(file)
    });
}



export const readFiles = async (files: File[]) => {
    let result: any[] = [];
    if (Array.isArray(files) && files) {
        for (const file of files) {
            result.push(readFile(file));
        }
    }
   return Promise.all(result).then(value => value.map(el => el))
}
