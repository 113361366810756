import $api from "../http";
import {IError, IFileBody, IFileResponse} from "../models";
import {AxiosResponse} from "axios";
import {checkResponse} from "./checkResponse";
import {transformError} from "../helpers";

const filePath = '/File';

export const fetchFilesGet = async (): Promise<AxiosResponse<IError | IFileResponse[]>> => {
    return $api.get(filePath)
        .then(res =>  checkResponse(res))
        .catch(e =>  transformError(e))
};

export const fetchFilesPost = (data: IFileBody, controller: AbortController): Promise<AxiosResponse<IError | IFileResponse>> => {

    const headers = {
        "File-Format": data.format,
        "File-Dimensions": data.sizes.length > 1 ? data.sizes.join(';') : data.sizes[0],
    }
    return $api.post(
        filePath,
        data.file,
        {
            headers: {...headers,
                "Content-Type": `image/${headers["File-Format"]}`
            },
            signal: controller.signal
        })
        .then(res =>  checkResponse(res))
        .catch(e =>  transformError(e))
};

export const fetchFilesDelete = async (id:string): Promise<AxiosResponse<IError | string>> => {
    return $api.delete(`${filePath}?id=${id}`)
        .then(res =>  checkResponse(res))
        .catch(e =>  transformError(e))
};
