import {createAction, createSlice} from "@reduxjs/toolkit";
import {IAction, IFileResponse, IFilesState, IStoreState} from "../../models";

const initialState: IFilesState = {
    isLoad: false,
    isUpload: false,
    image: null,
    downFiles: [],
    uploadFiles: [],
};

const filesSlice = createSlice({
    name: 'files',
    initialState,
    reducers: {

        filesAddImageAction(state: IFilesState,action: IAction<IFileResponse | null>) {
            state.image = action.payload;
        },
        filesAddDownFilesAction(state: IFilesState,action: IAction<IFileResponse[] | []>) {
            state.downFiles = action.payload;
        },
        filesChangeLoad(state: IFilesState,action: IAction<boolean>) {
            state.isLoad = action.payload;
        },
        filesChangeUpload(state: IFilesState,action: IAction<boolean>) {
            state.isUpload = action.payload;
        },

        filesChangeIsUploadingAction(state: IFilesState,action: IAction<{status: boolean, id:number}>) {
            state.uploadFiles[action.payload.id].isUploading = action.payload.status;
        },
        filesChangeIsUploadedAction(state: IFilesState,action: IAction<{status: boolean, id:number}>) {
            state.uploadFiles[action.payload.id].isUploaded = action.payload.status;

        },
        filesAddFilesAction(state: IFilesState,action: IAction<any>) {
            state.uploadFiles = state.uploadFiles.concat(action.payload).flat();
        },
        filesDelUploadFileAction(state: IFilesState,action: IAction<string>) {
            state.uploadFiles = state.uploadFiles.filter(el => el.id !== action.payload);
        },
        filesAddUploadFileErrorAction(state: IFilesState,action: IAction<{error: {isError: boolean,msg: string[]}, id:number}>) {
            state.uploadFiles[action.payload.id].error = action.payload.error;
        },
        filesAddUploadFileSizeAction(state: IFilesState,action: IAction<{size: string, id: number}>) {
            state.uploadFiles[action.payload.id].data.sizes = state.uploadFiles[action.payload.id].data.sizes.concat(action.payload.size);
        },
        filesDelUploadFileSizeAction(state: IFilesState,action: IAction<{size: string, id: number}>) {
            state.uploadFiles[action.payload.id].data.sizes = state.uploadFiles[action.payload.id].data.sizes.filter(el => el !== action.payload.size);
        }
    }
});

export const filesReducer = filesSlice.reducer;

export const ADD_UPLOAD_FILES = 'ADD_UPLOAD_FILES';
export const UPLOAD_FILES = 'UPLOAD_FILES';
export const GET_FILES = 'GET_FILES';
export const DELETE_FILE = 'DELETE_FILE';
export const getFilesAction = createAction(GET_FILES,);
export const deleteFileAction = createAction(DELETE_FILE,payload => ({payload}));
export const addUploadFileAction = createAction(ADD_UPLOAD_FILES,payload => ({payload}));
export const uploadFilesAction = createAction(UPLOAD_FILES,);

export const {
    filesChangeLoad,
    filesDelUploadFileAction,
    filesAddFilesAction,
    filesChangeIsUploadedAction,
    filesChangeIsUploadingAction,
    filesAddDownFilesAction,
    filesAddImageAction,
    filesAddUploadFileErrorAction,
    filesChangeUpload,
    filesAddUploadFileSizeAction,
    filesDelUploadFileSizeAction,

} = filesSlice.actions;
export const uploadFilesSelector = (state: IStoreState) => state.files.uploadFiles;
export const filesImageSelector = (state: IStoreState) => state.files.image;
export const filesDownFilesSelector = (state: IStoreState) => state.files.downFiles;
export const filesLoadSelector = (state: IStoreState) => state.files.isLoad;
export const filesUploadSelector = (state: IStoreState) => state.files.isUpload;
