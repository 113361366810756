import {FC, useState} from "react";
import {IconButton, InputAdornment, TextField} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

type TProps = {
    value: string,
    onChange?: (e: any) => void,
    onBlur: (e: any) => void,
    error?: boolean,
    helperText?: string,
    name?: string,
    label?: string,
}


export const PasswordInput: FC<TProps> = (props) => {
    let [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    }

    return (
        <TextField
            margin='normal'
            autoComplete={'true'}
            id={props.name ? `outlined-password-input ${props.name}` : 'outlined-password-input'}
            label={props.label ? props.label : "Password"}
            variant='outlined'
            type={showPassword ? 'text' : 'password'}
            name={props.name ? props.name : 'password'}
            value={props.value}
            onChange={props.onChange}
            onBlur={props.onBlur}
            error={props.error}
            helperText={props.helperText}
            InputProps={{
                endAdornment:
                    <InputAdornment position='end'>
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                        >
                            {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                        </IconButton>
                    </InputAdornment>

            }}


        />
    )
}
