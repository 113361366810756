import {call, put, select} from "@redux-saga/core/effects";
import {
    addChangeEmailPasswordAction,
    addChangeKeyEmailAction,
    settingsChangeEmailSelector,
} from "../../slices/settingsSlice";

import {
    errorsChangeEmailUpdateAction,
    errorsUiUpdateAction
} from "../../slices/errorSlice";

import {uiChangeLoadingAction, uiChangeMessageAction, uiUpdateTimerAction} from "../../slices/uiSlice";
import {IChangeEmailBody, IError} from "../../../models";
import { fetchChangeEmail} from "../../../services/EmailService";
import {updateTokenSaga} from "../token-sagas/updateTokenSaga";


export function* changeEmailSaga(): Generator {
    try {
        yield put(uiChangeLoadingAction(true));
        yield put(errorsUiUpdateAction(null));
        yield put(errorsChangeEmailUpdateAction(null));

        let data: IChangeEmailBody | unknown = yield select(settingsChangeEmailSelector);

        let resp = yield call(() => fetchChangeEmail((data as IChangeEmailBody)));
        if ((resp as Object).hasOwnProperty('errorCode') && (resp as IError).errorCode !== 10) {
            throw resp;
        }

        else {

            yield put(addChangeKeyEmailAction(''));
            yield put(addChangeEmailPasswordAction(''));
            yield put(uiUpdateTimerAction(0));
            yield call(() => updateTokenSaga());

            yield put(uiChangeMessageAction({
                isShow: true,
                title: 'Changed!',
                desc: `Congratulations you successfully changed your email address`,
                role: 'inf'
            }))
        }

    }
    catch (e) {

        if ((e as IError).status) {
            yield put(errorsChangeEmailUpdateAction((e as IError)));
        }
        else {
            yield put(errorsUiUpdateAction((e as IError)));
        }
    }
    finally {
        yield put(uiChangeLoadingAction(false));

    }
}
