import React, {FC, useEffect} from "react";
import {Box, Button, Link, TextField, Typography} from "@mui/material";
import {Copyright} from "../../copyright";
import {PasswordRulesList} from "../../passwordRulesList";
import {validation} from '../../../schemas/validation.schemas';
import {useDispatch, useSelector} from "react-redux";
import {PasswordInput} from "../../password-input";
import {signUpAction, signUpStoreSelector} from "../../../store/slices/signUpSlice";
import {errorsSignUpSelector} from "../../../store/slices/errorSlice";
import {useClearErrorsNavigate, useFormikRef} from "../../../hooks";
import {setError} from "../../../helpers/setError";



export const FormSignUp: FC = () => {
    let signUpValue = useSelector(signUpStoreSelector)
    let dispatch = useDispatch();
    let error = useSelector(errorsSignUpSelector);
    const navigate = useClearErrorsNavigate();



    const onSubmit = (values: any) => {
        dispatch(signUpAction({email: values.email, password: values.password}));
    };


    const formikRef = useFormikRef(
        signUpValue,
        validation.signUp(),
        onSubmit,
    );
    const formik = formikRef.current


    useEffect(() => {
        if (error) {
            setError(formikRef.current,error,'signUp')
        }
        return;
    },[error,formikRef])


    return (
        <Box sx={{maxWidth: '300px', mt:10, marginLeft: 'auto', marginRight: 'auto'}}>
            <Typography fontSize='xx-large' component="h1" variant="h5">
                Sign Up
            </Typography>
            <Box className='form' component='form' sx={{mt: 1}} onSubmit={formik.handleSubmit}>
                <TextField
                    id='outlined-basic'
                    label='Email'
                    variant='outlined'
                    type='email'
                    name='email'
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={ (formik.touched?.email && Boolean(formik.errors?.email)) || Boolean(error)}
                    helperText={formik.errors?.email as string}
                    margin='normal'
                />

                <PasswordInput
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched?.password && Boolean(formik.errors?.password)}
                    helperText={formik.errors?.password as string}
                />

                <PasswordRulesList />

                <Button variant='contained' type="submit">Sign Up</Button>

                <Box textAlign='center' mt={1}>
                    <Typography>
                        Have an account?
                    </Typography>
                    <Link onClick={() => navigate('/signin')} sx={{mt: 1}}>SignIn</Link>
                </Box>

            </Box>
            <Copyright />
        </Box>

    )
}
export default FormSignUp;
