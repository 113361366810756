import {AxiosRequestConfig, AxiosResponse} from "axios";
import {ISignInResponse, IAuthBody, IError, ILetterResponse} from "../models/";
import $api from "../http";
import {transformError} from "../helpers";
import {checkResponse} from "./checkResponse";


const signInPath: string = '/auth/signin';
const signUpPath: string = 'SignUp';
const confirmEmailPath = `/User/email/confirm`;
const resendLatterPath = `/User/email/confirm/resend`;
const signOutPath = '/auth/signout';


export const fetchSignIn = async (data: IAuthBody): Promise<AxiosResponse<any> | IError> => {
    return $api.post<ISignInResponse>(signInPath,{
        email: data.email,
        password: data.password,
        rememberMe: data.rememberMe,
        accessType: data.accessType,
    }).then(res =>  checkResponse(res)).catch(e =>  transformError(e))
};

export const fetchSignUp = async (data: IAuthBody) => {
    return $api.post(signUpPath,{email: data.email, password: data.password})
        .then(res =>  checkResponse(res))
        .catch(e =>  transformError(e))
};


export const fetchConfirmEmail= async (data: string) => {
    return $api.post<AxiosRequestConfig>(confirmEmailPath,{key: data})
        .then(res =>  checkResponse(res))
        .catch(e =>  transformError(e));
};

export const fetchConfirmLatter = async (data?: any): Promise<AxiosResponse<ILetterResponse> | IError > => {
    return $api.post(`${resendLatterPath}`)
        .then(res =>  checkResponse(res))
        .catch(e =>  transformError(e))
}
export const fetchSignOut = async (): Promise<AxiosResponse<ILetterResponse> | IError > => {
    return $api.get(signOutPath)
        .then(res =>  checkResponse(res))
        .catch(e =>  transformError(e))
}

