import {call, put} from '@redux-saga/core/effects'
import {fetchSignIn} from "../../../services/AuthService";
import {addAccessTokenAction,addRefreshTokenAction, clearTokensAction} from "../../slices/tokensSlice";
import {IAction, IAuthBody, IError, ISignInResponse, IUserClaims,} from "../../../models";
import { REDIRECT} from "../../../routes/paths";
import { getContext } from "redux-saga/effects"
import {AxiosResponse} from "axios";
import {Router} from "@remix-run/router";
import {decodeJwtSaga} from "../token-sagas/decodeJwtSaga";
import {uiChangeLoadingAction} from "../../slices/uiSlice";
import {
    signInAddEmailAction,
    signInAddPasswordAction,
    signInClearStateAction
} from "../../slices/signInSlice";
import {errorsSignInUpdateAction, errorsUiUpdateAction} from "../../slices/errorSlice";
import {getUserDetailsAction, logOutUserAction} from "../../slices/userSlice";



export function* signInSaga(action: IAction<IAuthBody>): Generator<{type:String,payload?: any}> | Generator {
    try {

        const router: Router | unknown = yield (getContext('router'));
        yield (router as Router).navigate(REDIRECT,{replace: true});
        yield put(uiChangeLoadingAction(true));

        yield put(errorsSignInUpdateAction(null));
        yield put(errorsUiUpdateAction(null));

        yield put(signInAddEmailAction(action.payload.email));


        let response:AxiosResponse<ISignInResponse> | IError | unknown = yield call(() => fetchSignIn(action.payload));

        if (!(response as Object).hasOwnProperty('errorCode')) {

            yield put(addAccessTokenAction((response as ISignInResponse).accessToken));
            yield put(addRefreshTokenAction((response as ISignInResponse).refreshToken));

            let userData = yield call(() => decodeJwtSaga((response as ISignInResponse).userClaims));

            if (!(userData as object).hasOwnProperty('errorCode')) {
                const router: Router | unknown = yield (getContext('router'));
                yield (router as Router).navigate(REDIRECT,{replace: true});
                yield put(signInClearStateAction());

                yield (userData as IUserClaims).email_confirmed ? yield put(getUserDetailsAction()) : null;

            }

            else {
                yield put(logOutUserAction())
                yield put(clearTokensAction())
                throw userData;
            }

        }
        else {
            throw response;
        }

    }
    catch (e: any) {
        if (e.status) {
            yield put(errorsSignInUpdateAction(e));
            yield put(signInAddPasswordAction(action.payload.password));
        }
        else {
            yield put(errorsUiUpdateAction( e as IError))
        }


    }
    finally {
        yield put(uiChangeLoadingAction(false));
    }
}
